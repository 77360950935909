<template>
  <div class="flex items-center flex-grow w-3/5 pr-2 sm:pr-0">
    <div class="w-10 h-10 md:w-14 md:h-14 mr-2 flex-shrink-0">
      <img
        class="w-full h-auto"
        :src="variant.ImageThumbnailUrl"
        :alt="variant.name"
      />
    </div>
    <div class="item-info">
      <div class="item-name">
        <div class="prod-name-tag flex gap-1 items-center">
          <p>{{ variant.opt1 ? variant.opt1 : variant.name }}</p>
          <p>
            <span
              v-if="isLoggedIn && variant.banned == 'true'"
              class="n-icon"
              :content="
                `This product cannot be shipped to ${getSelectedAddress.state}`
              "
              v-tippy="{ placement: 'bottom-end' }"
            >
              <Icon name="triangle-warn" class="w-4 h-4" />
            </span>
          </p>
        </div>

        <div class="stock-info" v-if="isLoggedIn">
          <p
            v-if="
              variant.banned == 'false' &&
                !product.licenseRequired &&
                variant.price != null &&
                variant.price != 0
            "
            class="mob-price"
          >
            {{
              variant.available_stock == 0
                ? variant.first_landed_date == null &&
                  variant.price == 0 &&
                  variant.price == null
                  ? null
                  : "$" + getPrice(variant, qty, product.bulk_discount)
                : "$" + getPrice(variant, qty, product.bulk_discount)
            }}<span class="px-1">&#x2022;</span>
          </p>
          <!-- <p class="font-light" style="font-size: 8px">item1, item2, item3...</p> -->

          <span>{{
            variant.available_stock && variant.MaxOrderQty > 0
              ? "In Stock"
              : variant.first_landed_date == null
              ? "Coming Soon"
              : "Out of Stock"
          }}</span>
          <Badge
            name="New"
            class="ml-2"
            v-if="isLoggedIn && isNew() && !product.licenseRequired"
            variant="primary"
          />
          <Badge
            :name="variant.badge_title ? variant.badge_title : 'Clearance'"
            class="ml-2"
            v-else-if="
              isLoggedIn && variant.is_clearance && !product.licenseRequired
            "
          />
          <Badge
            :name="variant.badge_title ? variant.badge_title : 'Discontinued'"
            class="ml-2"
            v-else-if="
              isLoggedIn && variant.is_discontinued && !product.licenseRequired
            "
          />
        </div>
      </div>
      <span
        v-if="
          isLoggedIn &&
            variant.banned == 'false' &&
            !product.licenseRequired &&
            variant.price != null &&
            variant.price != 0
        "
        class="price"
        >{{
          variant.available_stock == 0
            ? variant.first_landed_date == null &&
              variant.price == 0 &&
              variant.price == null
              ? null
              : "$" + getPrice(variant, qty, product.bulk_discount)
            : "$" + getPrice(variant, qty, product.bulk_discount)
        }}</span
      >
      <span class="price" v-else></span>
    </div>
  </div>
  <div
    v-if="isLoggedIn && variant.banned == 'false' && !product.licenseRequired"
    class="w-30 sm:w-32 md:w-48 flex items-center justify-end space-x-4"
  >
    <PlusMinusInput
      v-if="
        variant.available_stock > 0 &&
          variant.banned == 'false' &&
          !product.licenseRequired &&
          variant.price != null &&
          variant.price != 0
      "
      :id="variant.id"
      class="medium"
      v-on:getQuantity="updateCart"
      v-model:modelValue="qty"
      :min="0"
      :max="
        variant.MaxOrderQty > variant.available_stock
          ? variant.available_stock
          : variant.MaxOrderQty
      "
      :stock="variant.available_stock"
    />
    <!-- :max="variant.available_stock" -->
    <button
      v-else-if="variant.has_notification"
      :id="variant.id"
      @click="doNotNotify(variant)"
      class="notify-bell "
      :class="{ undo: true }"
    >
      <span
        class="hidden md:inline-block uppercase text-tiny font-normal text-brand-gray tracking-wider"
        >Notification Set</span
      >
      <div
        class="n-icon bg-black"
        v-tippy="{
          content: 'You will be notified when this product is available.',
          placement: 'bottom-end',
        }"
      >
        <Icon name="bell-off" class="w-4 h-4" />
      </div>
    </button>
    <button
      v-else
      @click="Notify(variant)"
      :id="variant.id"
      class="notify-bell"
    >
      <span
        class="hidden md:inline-block uppercase text-tiny font-normal text-brand-gray tracking-wider"
      ></span>
      <div
        class="n-icon text-brand-gray"
        v-tippy="{
          content: 'Click here to be notified when this product is available.',
          placement: 'bottom-end',
        }"
      >
        <Icon name="bell-ring" />
      </div>
    </button>
  </div>
  <div v-else class="locked-holder">
    <div
      class="n-icon"
      v-tippy="{
        content:getContent(),
        placement: 'bottom-end',
      }"
    >
      <Icon name="prohibited" class="w-6 h-6" />
    </div>
  </div>

  <!-- <div>
      <span  style="font-size: 9px" :key="sub_v.id" v-for="sub_v in variant.sub_variants">{{sub_v.name}}</span>
    </div>
  <h1>Test</h1> -->
</template>

<script>
import Mymixin from "@/modules/mixins";
import { inject } from "vue";
import debounce from "lodash/debounce";
import Notifications from "@/modules/Notifications";
import { mapGetters } from "vuex";

export default {
  name: "Variant",
  props: ["variant", "isLoggedIn", "product", "productsQty", "list_id"],
  mixins: [Mymixin],
  watch: {
    cart: {
      handler: "mountCartData",
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["cart", "getSelectedAddress"]),
  },
  data() {
    const axios = inject("axios");
    return {
      axios,
      qty: 0,
      preQty: 0,
      // cart: this.$store.getters.cart,
      text: "Don't Notify me",
      data: "Notify me",
    };
  },
  methods: {
    mountCartData() {
      if (this.isLoggedIn) {
        if (this.cart.itemsByCat?.length > 0) {
          this.cart.itemsByCat.forEach((element) => {
            element.items.forEach((item) => {
              if (item.variantId == this.variant.id) {
                this.qty = item.qty;
                this.preQty = item.qty;
              }
            });
          });

          // For Update Variant When Any Item Deleted From Cart
          if (this.cart.deleted_variant_id == this.variant.id) {
            this.qty = 0;
          }
        } else {
          this.qty = 0;
        }
      }
    },

    // Update Cart Data in Store
    updateCart: debounce(function(d) {
      let [qty, add] = d;
      let path = this.$route.path.split("/");
      
      let data = {
        variant_id: this.variant.id,
        qty: qty,
        segmentQty: add ? qty - this.preQty : this.preQty - qty,
        axios: this.axios,
        route: this.$route,
        add,
        position: this.$store.getters.getProPosition,
        sort: this.$store.getters.getSortValue,
        list_id: this.list_id ? this.list_id : path[2] ? path[2] : path[1],
        list_type: path[1],
      };
      this.preQty = qty;
      if (qty === 0) {
        this.$store.dispatch("removeFromCart", data);
      } else {
        this.$store.dispatch("UpdateCart", data);
      }
    }, 200),

    getPrice(val, qty, bulk) {
      val.qty = qty;
      val.bulk = bulk ? bulk : [];
      if (bulk) {
        // const dis = bulk.filter((r) => this.productsQty >= r.min_quantity);
        const dis = bulk.filter(
          (r) =>
            parseInt(this.cart.bulkTags[this.product.bulk_tag]) >=
            r.min_quantity
        );
        const discount_row = dis[dis.length - 1];
        let discount_price = discount_row
          ? (
              val.price -
              (val.price * discount_row.discount_percentage) / 100
            ).toFixed(2)
          : Number(val.price).toFixed(2);
          if(discount_price < val.min_price){
            if(val.price > val.min_price){
              discount_price = Math.max(discount_price, val.min_price);
            }
          }
        return Number(discount_price).toFixed(2);
      } else {
        return Number(val.price).toFixed(2);
      }
    },
    Notify(id) {
      this.notifyme(id, this.product);
      let idd = id.id;
      this.product.variants.forEach((item) => {
        if (item.id == idd) {
          item.has_notification = true;
        }
      });
    },
    doNotNotify(id) {
      this.deleteNotify(id, this.product);
      let idd = id.id;
      this.product.variants.forEach((item) => {
        if (item.id == idd) {
          item.has_notification = null;
        }
      });
    },
    isNew() {
      const date = new Date();
      const firstLandedDate = new Date(this.variant.first_landed_date);
      const timeDifference = date.getTime() - firstLandedDate.getTime();
      const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

      return daysDifference >= 0 && daysDifference <= 30;
    },
    getContent() {
      return this.variant.banned == "true"
        ? "This product cannot be shipped to your selected address."
        : this.product.licenseRequired
        ? "This product requires a license to purchase."
        : null;
    },
  },
  setup() {
    const { notify, notifyme, deleteNotify } = Notifications();
    return {
      notify,
      notifyme,
      deleteNotify,
    };
  },
};
</script>

<style lang="scss" scoped>
.locked-holder {
  @apply flex w-16 md:w-48 items-center justify-end space-x-4 pr-4 relative;

  &:before {
    content: "";
    @apply hidden sm:block absolute -left-36 right-20 h-0.5 bg-theme-body bg-opacity-40;
  }
}
.prod-name-tag {
  background: #fbfbfd;
  z-index: 20;
}
.notify-bell {
  @apply flex items-center space-x-3 uppercase text-xs tracking-tight;
  .n-icon {
    @apply inline-flex items-center justify-center w-10 h-10 bg-brand-red text-white rounded;

    svg {
      @apply w-5 h-5;
    }
  }
  &.undo {
    .n-icon {
      @apply bg-theme-secondary;
    }
  }
}
</style>
