<template>
  <div class="w-full flex flex-col space-y-8 hide-empty ">
    <!-- brand -->
    <div v-if="brFilterCount > 1">
      <ul class="filters-menu space-y-4">
        <li
          :class="disableBorder ? 'pt-2' : 'border-t border-gray-200 pt-4'"
          v-if="!hideTitle"
        >
          <p
            class="text-sm uppercase tracking-wide text-brand-body text-opacity-60"
          >
            FILTER BY BRANDS
          </p>
        </li>
        <li
          v-for="option in brFilters"
          :key="option.id"
          class="flex items-center justify-between "
        >
          <div
            class="flex items-center whitespace-nowrap text-ellipsis overflow-hidden "
          >
            <span>
              <input
                v-if="brFilterCount.length == 1"
                checked
                disabled
                type="checkbox"
                class="h-4 w-4 border-2 border-brand-gray border-opacity-50 rounded text-brand-secondary focus:ring-transparent"
              />
              <input
                v-else
                :id="`${option.slug}-${option.id}`"
                name="brandSort"
                :checked="isCheck(option.id, `${option.slug}-${option.id}`)"
                @change="selectedFilter($event, 'brand')"
                :value="option"
                type="checkbox"
                class="h-4 w-4 border-2 border-brand-gray border-opacity-50 rounded text-brand-secondary focus:ring-transparent"
              />
              <label
                :for="`${option.slug}-${option.id}`"
                class="ml-3 pr-6 text-sm font-medium text-brand-gray whitespace-nowrap"
              >
                {{ option.name }}
              </label>
            </span>
          </div>
        </li>
        <div
          v-if="brFilterCount > 5 && !disableShowMore"
          @click="brShowMore = !brShowMore"
          class="toggle-view-btn"
        >
          <span>Show {{ brShowMore ? "Less" : "More" }}</span>
          <Icon
            name="down-arrow"
            class="w-5 h-5"
            :class="brShowMore ? 'transform rotate-180' : ''"
          />
        </div>
      </ul>
    </div>
    <p v-else>No brands found!</p>
    <!-- brand -->
  </div>
</template>
<script>
import clone from "lodash/clone";
export default {
  name: "BrandsFilters",
  props: ["hideTitle", "disableShowMore", "disableBorder"],
  data() {
    return {
      brShowMore: false,
    };
  },
  methods: {
    selectedFilter(e, type) {
      if (e.target.checked) {
        this.$store.dispatch("selectedOption", { ...e.target._value, type });
      } else {
        this.$store.dispatch("removeOption", { ...e.target._value, type });
      }
      // scroll on top
      document.querySelector("body").scrollIntoView({ behavior: "smooth" });
    },
    isCheck(id, el_id) {
      if (this.selected) {
        this.selected.forEach((el) => {
          if (id == el.id) {
            setTimeout(() => {
              document.getElementById(el_id).checked = true;
            }, 300);
            return true;
          }
        });
      } else return false;
    },
  },
  computed: {
    selected() {
      return this.$store.getters.getSelected;
    },
    brFilters() {
      if (this.brShowMore || this.disableShowMore) {
        return this.$store.getters.getBrandFilter;
      }
      const filters = clone(this.$store.getters.getBrandFilter);
      return filters.splice(0, 5);
    },
    brFilterCount() {
      return this.$store.getters.getBrandFilter.length;
    },
  },
};
</script>
<style lang="scss" scoped>
.filters-menu {
  @apply w-full list-none px-3 select-none;
  li {
    @apply w-full;
    a {
      @apply w-full flex items-center space-x-3 text-sm font-medium text-brand-gray py-3 px-6 border-l-4 border-transparent hover:border-theme-primary bg-theme-primary bg-opacity-0 hover:bg-opacity-5 leading-none;
      &.active {
        @apply bg-theme-primary bg-opacity-5 border-theme-primary text-theme-dark;
      }
    }
  }
  input,
  label {
    cursor: pointer;
  }
}
</style>
