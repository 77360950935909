<template>
  <Disclosure as="div" class="items-list-card" v-slot="{ open }">
    <div class="items-list-card-inner">
      <p class="ranking-bubble" v-if="rank">
        <span>#{{ rank }}</span>
      </p>
      <DisclosureButton
        class="item-card-btn"
        @click="trackingActivity(open)"
      ></DisclosureButton>
      <div class="item cursor-pointer">
        <router-link
          class="item-img"
          @click="goToProductPage()"
          :to="item.is_bundle == 'true' ? '/bundles/' + item.slug :'/products/' + item.slug"
        >
          <img :src="item.image_url" alt="" />
        </router-link>

        <router-link
          class="item-info"
          @click="goToProductPage()"
          :to="item.is_bundle == 'true' ? '/bundles/' + item.slug :'/products/' + item.slug"
        >
          <p>
            {{ item.name }}
          </p>
          <span>{{ item.brand }} - {{ item.product_type }}</span>
        </router-link>
      </div>
      <button
        v-if="isLoggedIn"
        @click="addtowatchlist(item)"
        class="item-fav"
        :class="{ del: $route.path.includes('watchlist') }"
      >
        <Icon
          v-tippy="{
            content:
              item.watchlist == null
                ? 'Click here to add this product to your favorites'
                : 'Click here to remove this product from your favorites',
            placement: 'top',
          }"
          :name="item.watchlist == null ? 'heart-filled' : 'heart-outline'"
        />
      </button>
      <DisclosureButton
        as="div"
        class="item-option-btn"
        v-if="item.variants && item.variants.length"
        @click="trackingActivity(open)"
      >
        <p>Options</p>
        <Icon name="down-arrow" />
      </DisclosureButton>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <DisclosurePanel as="div" class="items-content-panel">
        <div
          class="item-variant"
          v-for="(variant, index) in sortVariants(item.variants, isLoggedIn)"
          :key="variant.name + '-' + index"
        >
          <Variant
            :variant="variant"
            :product="item"
            :isLoggedIn="isLoggedIn"
            :list_id="list_id"
          />
        </div>
      </DisclosurePanel>
    </transition>
  </Disclosure>
</template>
<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import Variant from "./Variant.vue";
import Categories from "@/modules/Categories";
import Watchlist from "@/modules/Watchlist";
import Segments from "@/modules/Segments";
import { useRoute } from "vue-router";
import Mymixin from "@/modules/mixins";
import { numeric } from "@vuelidate/validators";
import { mapActions, useStore } from "vuex";

export default {
  name: "item-dropdown",
  mixins: [Mymixin],
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Variant,
  },
  props: {
    item: Object,
    position: numeric,
    rank: Number,
    list_id: String,
  },
  computed: {
    ...mapActions(["setProPosition"]),
  },
  methods: {
    addtowatchlist(item) {
      this.setWatchlist(item);
      item.watchlist = item.watchlist == null ? 1 : null;
      this.$parent.$emit("removeItem", item);
    },
    goToProductPage(r) {
      let ul = this.$route.path.split('/');
      let list_type = ul[1];
      let types = ['clearance', 'trending', 'coming-soon', 'favorites'];

      if(types.includes(ul[1]))
        list_type = 'collection';

      this.$store.dispatch("setList", {
        list_id: this.list_id ? this.list_id : ul[2] ? ul[2] : ul[1],
        list_type: list_type,
        position: this.position,
      });
      this.$store.dispatch("setProPosition", this.position);
      this.trackingActivity(false);
    },
  },
  setup(props) {
    const store = useStore();
    const { setWatchlist } = Watchlist();
    const { isLoggedIn } = Categories();
    const { ProductClicked,ProductClickedNonLoggedIn } = Segments();
    const route = useRoute();

    function trackingActivity(i) {

      let ul = route.path.split('/');
      let list_type = ul[1];
      let types = ['clearance', 'trending', 'coming-soon', 'favorites'];
      if(types.includes(ul[1]))
        list_type = 'collection';

      let data = {
        id: props.list_id ? props.list_id : ul[2] ? ul[2] : ul[1],
        type: list_type,
        price: Math.max(...props.item.variants.map(r => r.wholesale_price)).toFixed(2),
      };
      if (isLoggedIn) {
        if(!i){
          store.dispatch("setProPosition", props.position);
          ProductClicked(route, props.item, props.position, data);
        }
      }else{
        ProductClickedNonLoggedIn(route, props.item, props.position, data);
      }
    }
    return {
      isLoggedIn,
      setWatchlist,
      trackingActivity,
    };
  },
};
</script>
